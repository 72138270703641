import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/pgegenfurtner/Projekte/interness/atacama/atacama-packages/packages/web-core/src/templates/mdx/default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Wrapper = makeShortcode("Wrapper");
const Separator = makeShortcode("Separator");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <hr></hr>
    <Wrapper mdxType="Wrapper">
      <h1>{`Kooperierende Verbände`}</h1>
      <p>{`Liebe Kolleginnen und Kollegen,`}</p>
      <p>{`Nur eine starke HP-Gemeinschaft kann sich Gehör verschaffen bei der Vertretung unserer Interessen in der Öffentlichkeit (bei den Medien, Krankenkassen, beim Gesetzgeber, in diversen Kommissionen, Gesundheitsämter…)`}</p>
      <p><strong parentName="p">{`Daher suchen und pflegen wir Kooperationen mit anderen HP-Verbänden.`}</strong></p>
      <p>{`Neben unserer Mitgliedschaft im Dachverband Deutscher Heilpraktiker e.V. (DDH) halten wir enge Kontakte zum `}<strong parentName="p">{`Freie Heilpraktiker e.V. (FH)`}</strong>{` mit Sitz in Düsseldorf.`}</p>
      <p>{`Dadurch haben sich folgende Vorteile ergeben: Alle Mitglieder dieses Verbandes können die Veranstaltungen, Kongresse und die regionalen Fachfortbildungen zu den gleichen Konditionen in Anspruch nehmen.`}</p>
      <p>{`Das gilt für den Heilpraktiker-Kongress in Düsseldorf / Neuss (FH, Mitte September) und das Heilpraktiker-Symposium in Dresden (FH, Mitte November).
Infos und Anmeldung unter `}<a parentName="p" {...{
          "href": "https://www.freieheilpraktiker.com",
          "rel": "nofollow noopener noreferrer external"
        }}>{`www.freieheilpraktiker.com`}</a></p>
      <p>{`Wir wünschen Ihnen viele interessante und lehrreiche Veranstaltungen.`}</p>
      <Separator mdxType="Separator" />
      <p>{`Liebe Kolleginnen und Kollegen,`}</p>
      <p>{`nach ersten Kontakten und sehr fruchtbaren Vorgesprächen haben die Vorstände von FVDH und der `}<strong parentName="p">{`Deutschen Gesellschaft für Therapeutische Hypnose und Hypnoseforschung e.V. (GTH)`}</strong>{` eine Kooperation vereinbart.`}</p>
      <p>{`Ziel der Zusammenarbeit ist der Info-Austausch und die Förderung der Aus- und Weiterbildung. Interessant sind – neben den Hypnose-Ausbildungsseminaren – auch die Weiterbildungsangebote der GTH zur Integration der Hypnose in medizinischen Berufen zur Intensivierung anderer Therapieverfahren!`}</p>
      <p>{`Wir wünschen uns und den Mitgliedern beider Verbände eine fruchtbare und erfolgreiche Zusammenarbeit.`}</p>
    </Wrapper>
    <hr></hr>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      